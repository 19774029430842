import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  connectFunctionsEmulator,
  getFunctions,
} from "firebase/functions";
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  Carousel,
} from "@material-tailwind/react";
import {
  ArrowUpIcon,
  ChartBarIcon,
  TicketIcon,
  CreditCardIcon,
  ClockIcon,
  CheckIcon
} from "@heroicons/react/24/outline";
import { StatisticsCard } from "widgets/cards/statistics-card";
import { collection, doc, getDoc, orderBy, query } from "firebase/firestore";
import db from "../../firebase";
import { ArrowDownIcon, ArrowLeftCircleIcon, CalendarDaysIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { formatFirebaseTimestamp, getUniquePriceHistoryChanges } from "utils";
import { formatPrice, friendlyTimeFormat, locationFromListingScrapeData } from "../../utils";
import { ListingDetailImage } from "../../components/ListingDetailImage";
import { ListingDetailsChart } from "../../components/ListingDetailsChart";

const ListingDetails = ({ulid}) => {
  const { slug } = useParams();

  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };

  const uniqueListingId = slug ? slug : ulid;

  const col = "listing_aggregations_v1";
  const queryListing = query(doc(db, `${col}/${uniqueListingId}`));
  const [listingData] = useDocument(queryListing, options);

  const queryListingScrapes = query(
    collection(db, `${col}/${uniqueListingId}/listing_scrapes`),
    orderBy("scrapeTs", "asc"),
  );
  
  const [listingScrapesData] = useCollection(queryListingScrapes, options);

  function timeseriesFromData(data) {
    let priceUSDOfLastPriceChange = data[0].data().price_usd;
    const points = data.map((item, index) => {
      const dt = item.data().scrapeTs.toDate();
      if (index !== 0){
        if (item.data().currency === data[index-1].data().currency){
          // use the last price_usd unless the underlying price has changed
          const price = item.data().price;
          const prevPrice = data[index-1].data().price;
          if (price !== prevPrice){
            priceUSDOfLastPriceChange = item.data().price_usd;
            // console.log(`price diff ${dt} ${price} ${prevPrice}`)
            return {dt, price: item.data().price_usd};
          } else {
            return {dt, price: priceUSDOfLastPriceChange};
          }
        } else {
          priceUSDOfLastPriceChange = item.data().price_usd;
          return {dt, price: item.data().price_usd};
        }
      } else {
        return {dt, price: item.data().price_usd};
      }

    });
    return points;
  }

  function allNaN(timeseries){
    return timeseries.filter(item => isNaN(item.price)).length === timeseries.length;
  }

  const navigate = useNavigate()

  const handleBack = ()=>{
    navigate(-1);
  }
  const functions = getFunctions();
  
  if (window.location.href.indexOf('localhost') > -1) {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001)
}

  const lastListingScrape = () => {
    if (!listingScrapesData) return
    const latestListingScrapeData = listingScrapesData.docs[listingScrapesData.docs.length-1].data();
    return latestListingScrapeData;
  }

  const dealerInfo = () => {

    const ret = {
      dealerName: 'Loading...',
      dealerPhoneNumber: "Loading...",
      dealerLocation: "Loading..."
    }

    if (!listingData){
      return ret;
    }

    ret.dealerName = listingData.data().dealerName;
    ret.dealerPhoneNumber = listingData.data().dealerPhoneNumber
    ret.individualName = listingData.data().individualName;
    ret.dealerLocation = listingData.data().dealerLocation;

    
    if (ret.dealerName){
      ret.dealerName = ret.dealerName.replaceAll(`&amp;`,``);
      ret.dealerName = ret.dealerName.replaceAll(` ,`,`,`);
    }
    if (ret.individualName){
      ret.individualName = ret.individualName.replaceAll(`&amp;`,``);
      ret.individualName = ret.individualName.replaceAll(` ,`,`,`);
    }
    if (ret.dealerLocation){
      ret.dealerLocation = ret.dealerLocation.replaceAll(`&amp;`,``);
      ret.dealerLocation = ret.dealerLocation.replaceAll(` ,`,`,`);
    }
    return ret;
  }

  const [urls, setUrls] = useState();

  useEffect(() => {
    const path = `listing_aggregations_v1/${uniqueListingId}/listing_images/img`;
    const q = query(doc(db, path));
    
    getDoc(q).then((doc) => {
      if (doc.data()){
        // console.log(doc.data().urls);
        setUrls(doc.data().urls);
      } else{
        console.log(`doc does not exist`);
      }        
    });
  },[uniqueListingId]);

  return (
    <>

      <div className="mb-0 p-0">

          <IconButton
            variant="text"
            color="blue"
            onClick={handleBack}
            className="border-solid border-2"
          >
            <ArrowLeftCircleIcon className="h-7 w-7 text-blue-500" />
          </IconButton>

      </div>

      <div className="mb-12 grid gap-y-5 gap-x-3 md:grid-cols-2 xl:grid-cols-2">

        <div className="col-span-1 h-full">

          {listingData && 
            <ListingDetailImage
            src={listingData.data().imageURL}
            price={
              formatPrice(listingData.data().price, listingData.data().currency)
            }
            title={listingData.data().title}
            reg={listingData.data().reg}
            site={listingData.data().source}
            listingURL={listingData.data().listingURL}
            lastSeen={listingData.data().last_seen}
            location={locationFromListingScrapeData(lastListingScrape())}
            country={listingData.data().country}
            year={listingData.data().year}
            uniqueListingId={listingData.data().uniqueListingId}
          />}
        </div>      
        {listingData && urls &&

        <div className="col-span-1">
            <Card className="h-full">
              <Carousel loop={true} autoplay={false} className="rounded-xl">
              {urls && urls.length && urls.map((m)=>{
                return <img
                src={m.link}
                alt=""
                className="h-full max-h-96 w-full object-cover"
              />
              })}
              </Carousel>
            </Card>
        </div> }
      </div>
      
      <div className="mb-12 grid gap-y-8 gap-x-3 md:grid-cols-5 xl:grid-cols-5">

      {listingData && listingData.data().timeState && (
        <StatisticsCard
          key={"timing"}
          value={
            listingData.data().timeState.totalTime
          }
          title={"Total Time"}
          icon={React.createElement(ClockIcon, {
            className: "w-6 h-6 text-white",
          })}
          footer={
            <>  
              <Typography className="font-normal text-sm text-blue-gray-600">
                Number of Engines: {listingData.data().timeState.numberOfEngines}
              </Typography>
              <Typography className="font-normal text-sm text-blue-gray-600">
                Engine 1: {listingData.data().timeState.engine1Time}
              </Typography>
              <Typography className="font-normal text-sm text-blue-gray-600">
                Engine 2: {listingData.data().timeState.engine2Time}
              </Typography>
            </>
         }
        />)}


      {listingData && (
        <StatisticsCard
          key={"dealer"}
          value={
            dealerInfo().dealerPhoneNumber
          }
          title={"Dealer"}
          icon={React.createElement(PhoneIcon, {
            className: "w-6 h-6 text-white",
          })}
          footer={
            <>  
              <Typography className="font-normal text-sm text-blue-gray-600">
              {dealerInfo().dealerName}
              </Typography>
              <Typography className="font-normal text-sm text-blue-gray-600">
                Contact: {dealerInfo().individualName}
              </Typography>
              <Typography className="font-normal text-sm text-blue-gray-600">
                Location: {dealerInfo().dealerLocation}
              </Typography>
            </>
         }
        />)}

      {listingData && listingScrapesData  &&(
        <StatisticsCard
          key={"price"}
          //   rest={"jello"}
          value={
            listingData ? formatPrice(listingData.data().price, listingData.data().currency) : "loading..."
          }
          title={"Current Price"}
          icon={React.createElement(CreditCardIcon, {
            className: "w-6 h-6 text-white",
          })}
          footer={
            <div>
            <Typography className="font-normal text-sm text-blue-gray-600">
              {"Orignal Price "}
              <strong className={"text-blue-gray"}>
              {listingData ? formatPrice(listingScrapesData.docs[0].data().price, listingScrapesData.docs[0].data().currency) : "loading..."}</strong>
            </Typography>
            <Typography className="font-normal text-sm text-blue-gray-600">
              {"active for "}
              <strong className={"text-blue-gray"}>
                {Math.ceil(listingData.data().days_active)}</strong>&nbsp;{ Math.ceil(listingData.data().days_active) === 1 ? "day" : "days"}
            </Typography>
            </div>
          }
        />)}

      {listingData && (<StatisticsCard
          key={"price_change"}
          //   rest={"jello"}
          value={
            listingData ? formatPrice(listingData.data().price_change_usd, "USD") : "loading..."
          }
          title={"Price Change vs. original"}
          icon={React.createElement(ChartBarIcon, {
            className: "w-6 h-6 text-red",
          })}
          footer={
            <Typography className="font-normal text-sm text-blue-gray-600">
              <strong
                className={
                  listingData && listingData.data().price_change_pct_usd >= 0
                    ? "text-green-500"
                    : "text-red-500"
                }
              >
                {Math.abs(listingData.data().price_change_pct_usd) > 0 ? Math.ceil(listingData.data().price_change_pct_usd * 100) + "%" : ""}
              </strong>
              &nbsp;
              {Math.abs(listingData.data().price_change_pct_usd) > 0  ? (listingData.data().price_change_pct_usd >= 0 ? "increase" : "decrease"): ""}
              {Math.abs(listingData.data().price_change_pct_usd) === 0  ? "No change" : ""}
            </Typography>
          }
        />)}

        {listingData && (
          <StatisticsCard

            key={"price_changes"}
            value={
              friendlyTimeFormat(listingData.data().last_change_dt)
            }
            title={"Since last price change"}
            icon={React.createElement(CalendarDaysIcon, {
              className: "w-6 h-6 text-white",
            })}
            footer={
              <Typography className="font-normal text-sm text-blue-gray-600">
                <strong>
                  {listingData.data().no_price_changes}
                </strong>
                &nbsp;
                {(listingData.data().no_price_changes > 1) || (listingData.data().no_price_changes === 0)
                  ? "price changes"
                  : "price change"}
              </Typography>
            }
          />
        )}
      </div>

      {listingData && <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-10">
       
      <div className="xl:col-span-2 col-span-1">
        <Card className="h-full">
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 p-6"
          >
            <Typography variant="h6" color="blue-gray" className="mb-2">
              Price Changes
            </Typography>
            
          </CardHeader>
          {listingScrapesData && 
          <CardBody>
           {
              getUniquePriceHistoryChanges(listingScrapesData.docs).map(
                ({ price_usd, currency, scrapeTs, change }, key) => (
                  <div key={key} className="flex items-start gap-4 py-3">
                    <div
                      className={`relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] ${
                        key === listingScrapesData.docs.length - 1
                          ? "after:h-0"
                          : "after:h-4/6"
                      }`}
                    >
                    
                      {change === 1 && (
                        <ArrowUpIcon
                          strokeWidth={3}
                          className="h-5 w-5 text-green-500"
                        />
                      )}
                      {change === -1 && (
                        <ArrowDownIcon
                          strokeWidth={5}
                          className="h-5 w-5 text-red-500"
                        />
                      )}
                      {change === 0 && (
                        <TicketIcon
                          strokeWidth={5}
                          className="h-5 w-5 text-green-500"
                        />
                      )}
                    </div>
                    <div>
                      <Typography

                        color="blue-gray"
                        className="block font-medium text-lg"
                      >
                        {formatPrice(price_usd, currency)}
                      </Typography>
                      <Typography
                        as="span"

                        className="text-md font-medium text-blue-gray-500"
                      >
                        {formatFirebaseTimestamp(scrapeTs)}
                      </Typography>
                    </div>
                  </div>
                )
                      )}
          </CardBody>}
        </Card>
        </div>

      <div className="col-span-1 h-full xl:col-span-5">
              <Card className="h-full">
              <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 flex items-center justify-between p-6"
          >
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-1">
                Chart
              </Typography>
              <Typography
                variant="small"
                className="flex items-center gap-1 font-normal text-blue-gray-600"
              >
              </Typography>
            </div>
          </CardHeader>
              <CardBody>
            {listingScrapesData && (!allNaN(timeseriesFromData(listingScrapesData.docs)) ? (
                <ListingDetailsChart
                  timeseries={timeseriesFromData(listingScrapesData.docs)}
                  priceChange={listingData.data().price_change_usd}
                  price={listingData.data().price}
                ></ListingDetailsChart>
              ) :   <Typography
              variant="small"
              className="text-[11px] font-medium uppercase text-blue-gray-400"
            >
              Data unavailable
            </Typography>)}
              </CardBody>
          </Card>
            
        </div>
        <div className="col-span-1  xl:col-span-3 p-0">

        <Card className="h-full">
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 flex items-center justify-between p-6"
          >
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-1">
                Price History
              </Typography>
              <Typography
                variant="small"
                className="flex items-center gap-1 font-normal text-blue-gray-600"
              >
                <CheckIcon strokeWidth={3} className="h-4 w-4 text-blue-500" />
                <strong>
                  {listingScrapesData && listingScrapesData.docs.length}
                </strong>
                scrapes
              </Typography>
            </div>
          </CardHeader>
          <CardBody className="flex items-stretch">

            <div className="overflow-y-auto max-h-96" >
              <table className="">
                <thead>
                  <tr>
                    {["date", "price"].map((el) => (
                      <th
                        key={el}
                        className="border-b border-blue-gray-50 py-3 px-4 text-left"
                      >
                        <Typography
                          variant="small"
                          className="text-[11px] font-medium uppercase text-blue-gray-400"
                        >
                          {el}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listingScrapesData &&
                    listingScrapesData.docs.map((doc, key) => {
                      const className = `py-2 px-4 ${
                        key === listingScrapesData.docs.length - 1
                          ? ""
                          : "border-b border-blue-gray-50"
                      }`;

                      return (
                        <tr key={key}>
                          <td className={className}>
                            <div className="flex items-center gap-4">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold"
                              >
                                {formatFirebaseTimestamp(doc.data().scrapeTs)}
                              </Typography>
                            </div>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              className="text-xs font-medium text-blue-gray-600"
                            >
                              {formatPrice(doc.data().price, doc.data().currency)}
                            </Typography>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
        </div>
        
      </div>}

    </>
  );
};

export default ListingDetails;
