import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export function PeriodSelector({setSelectedPeriod, selectedPeriod}) {
  
  const doSetPeriod= (e) =>{
    console.log(`setting selected period`, e)
    setSelectedPeriod(e.target.value)
  }

  return (
  
        <ToggleButtonGroup 
            value={selectedPeriod}
            exclusive
            onChange={(e)=> doSetPeriod(e)}
            aria-label="text alignment"
          >
            <ToggleButton value="7d" aria-label="7 days">
              7 days
            </ToggleButton>
            <ToggleButton value="1m" aria-label="1 month">
              1 month
            </ToggleButton>
            <ToggleButton value="3m" aria-label="3 months">
              3 months
            </ToggleButton>
            <ToggleButton value="6m" aria-label="6 months">
            6 months
            </ToggleButton>
            <ToggleButton value="1y" aria-label="1 year">
            1 year
            </ToggleButton>
            <ToggleButton value="2y" aria-label="2 years">
              2 years
            </ToggleButton>
            <ToggleButton disabled value="3y" aria-label="3 years">
              3 years
            </ToggleButton>
          </ToggleButtonGroup>
      );
}

export default PeriodSelector;
