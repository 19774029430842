import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  IconButton,
  Button,
  DialogBody,
  DialogFooter,
  Dialog,
  CardFooter,
  Spinner,
} from "@material-tailwind/react";
import { updateDoc, doc, deleteDoc, serverTimestamp } from "firebase/firestore";
import db from "../../firebase";

import Chart from "react-apexcharts";

import PropTypes from "prop-types";
import { UserAuth } from "../../context/AuthContext";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import ListingsView from "../../pages/dashboard/ListingsView";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import DashboardEditDialog from "./dashboard-edit-dialog";
import { Slider, SliderThumb, styled } from "@mui/material";
import { formatCompactNumber, formatPeriod } from "../../utils";
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';


function ArrowThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <ArrowDropDownSharpIcon className="arrow-bar"></ArrowDropDownSharpIcon>
    </SliderThumb>
  );
}

const StyledSlider = styled(Slider)(({ theme }) => ({
  // color: '#3a8589',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 40,
    width: 40,
    boxShadow: "none",
    top: "4px",
    backgroundColor: 'transparent',
    // border: '0px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .arrow-bar': {
      height: "2em",
      width: "2em",
      // backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-thumb:before': {
    boxShadow: "none",
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,

  },
}));

export function DashboardCard({ data }) {
  
  const functions = getFunctions();
  
  const defaultAnalysisData = {
    noListings:0,
    maxPrice:0,
    minPrice:0,
    avgPrice:0,
    noPriceCount:0,
    minDaysActive:0,
    maxDaysActive:0,
    avgDaysActive:0,
    noDaysActiveCount:0,
    minYears:0,
    maxYears:0,
    avgYears:0,
    noYearsCount:0,
    minTT:0,
    maxTT:0,
    avgTT:0,
    minE1T:0,
    maxE1T:0,
    avgE1T:0,
    minE2T:0,
    maxE2T:0,
    avgE2T:0,
    noTTCount:0,
    noE1TCount:0,
    noE2TCount:0,
    noExcluded: 0
  }

  const [dialogOpen, setDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [analysisData, setAnalysisData] = useState(defaultAnalysisData)

  if (window.location.href.indexOf('localhost') > -1) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  }

  const func = httpsCallable(functions, "getDashboardAnalysis");

  async function getDashboardAnalysis() {

    try {
      setIsLoading(true);
      // console.log(`getting data for panel id ${data.panelId}`, data)
      data.userId = user.uid;
      const response = await func(data);
      setAnalysisData(response.data)
      console.log(response.data)
    } catch(error){
    } finally {
      setIsLoading(false);
    }
  }

  const handleOpen = (data) =>{
    setDialogOpen(true);
  }

  const handleClose = async () =>{
    setDialogOpen(false);
    // await getDashboardAnalysis();
  }

  useEffect(()=>{
     getDashboardAnalysis()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const doEdit = async(name, manufacturer, models, period) => { 

    const docRef = doc(db, "user_data", user.uid, "dPanels", data.panelId );
    const manufacturers = [manufacturer];

    const dashPanelObj = {
      name,
      manufacturers,
      models,
      period,
      updated: serverTimestamp()

    }
  
    await updateDoc(docRef, dashPanelObj);
    data = dashPanelObj;
    // console.log(`new data ise `, dashPanelObj);
    await getDashboardAnalysis();
  }

  const { user } = UserAuth();

  const deleteDashPanel = async (panelId) => {
    await deleteDoc(doc(db, "user_data", user.uid, "dPanels", panelId))
  }

  const [openEdit, setEditOpen] = React.useState(false);

  const handleEditOpen = (d) => {
    setEditOpen(true);
  };
  
  const handleEditQuit = () =>{
    setEditOpen(false);
  }

  const handleSave = async (name, manufacturer, model, period) => {
    // console.log(`Handle Save...`, name, manufacturer, model, period);
    await doEdit(name, manufacturer, model, period);
    setEditOpen(false);
  }

  const priceMarks = [
    {
      value: analysisData.minPrice,
      label: formatCompactNumber(analysisData.minPrice)
    },
    {
      value: analysisData.avgPrice,
      label: formatCompactNumber(analysisData.avgPrice)
    },
    {
      value: analysisData.maxPrice,
      label: formatCompactNumber(analysisData.maxPrice)
    }
  ];

  const daysActiveMarks = [
    {
      value: analysisData.minDaysActive,
      label: analysisData.minDaysActive
    },
    {
      value: analysisData.avgDaysActive,
      label: analysisData.avgDaysActive
    },
    {
      value: analysisData.maxDaysActive,
      label: analysisData.maxDaysActive
    }
  ];

  const yearMarks = [
    {
      value: analysisData.minYears,
      label: analysisData.minYears
    },
    {
      value: analysisData.avgYears,
      label: analysisData.avgYears
    },
    {
      value: analysisData.maxYears,
      label: analysisData.maxYears
    }
  ];

  const totalTimeMarks = [
    {
      value: analysisData.minTT,
      label: analysisData.minTT
    },
    {
      value: analysisData.avgTT,
      label: analysisData.avgTT
    },
    {
      value: analysisData.maxTT,
      label: analysisData.maxTT
    }
  ];

  const engine1TimeMarks = [
    {
      value: analysisData.minE1T,
      label: analysisData.minE1T
    },
    {
      value: analysisData.avgE1T,
      label: analysisData.avgE1T
    },
    {
      value: analysisData.maxE1T,
      label: analysisData.maxE1T
    }
  ];

  const engine2TimeMarks = [
    {
      value: analysisData.minE2T,
      label: analysisData.minE2T
    },
    {
      value: analysisData.avgE2T,
      label: analysisData.avgE2T
    },
    {
      value: analysisData.maxE2T,
      label: analysisData.maxE2T
    }
  ];

  const models = data.models.map((m)=>m.label).join(", ").trim();

  const editDialog = () =>{
    if (data){
      return <DashboardEditDialog
      open={openEdit} 
      handleSave={handleSave}
      handleOpen={handleEditOpen}
      handleQuit={handleEditQuit}
      data={data}
      mode={"edit"}
    />
    }
  }

  const chartConfigDefault = {
    type: "pie",
    width: 80,
    height: 80,
    series: [analysisData.noPriceCount, analysisData.noResults-analysisData.noPriceCount],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      title: {
        show: "",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#d40f04", "#40a832"],
      legend: {
        show: false,
      },
    },
  };

  const chartConfigPrice = {...chartConfigDefault};
  chartConfigPrice["series"] = [analysisData.noPriceCount, analysisData.noResults-analysisData.noPriceCount]
  
  const chartConfigYears = {...chartConfigDefault};
  chartConfigYears["series"] = [analysisData.noYearsCount, analysisData.noResults-analysisData.noYearsCount]
  
  const chartConfigTT = {...chartConfigDefault};
  chartConfigTT["series"] = [analysisData.noTTCount, analysisData.noResults-analysisData.noTTCount]

  const chartConfigE1T = {...chartConfigDefault};
  chartConfigE1T["series"] = [analysisData.noE1TCount, analysisData.noResults-analysisData.noE1TCount]
  
  const chartConfigE2T = {...chartConfigDefault};
  if (analysisData.noEnginesMode === 2){
    chartConfigE2T["series"] = [analysisData.noE2TCount, analysisData.noResults-analysisData.noE2TCount]
  }
  

  const chartConfigDaysActive = {...chartConfigDefault};
  chartConfigDaysActive["series"] = [analysisData.noDaysActiveCount, analysisData.noResults-analysisData.noDaysActiveCount]


  const panels = () =>{

    if(isLoading){
      return <div className="items-center">
        <Spinner></Spinner>
        
        </div>
    }

    return ( <div className="m-5">
      <Typography key={"label-manufacturer"} variant="h4" className="font-normal text-blue-gray-600">
        {data.manufacturers[0].label}
      </Typography>
      <Typography key={"label-model"} variant="h6" className="font-normal text-blue-gray-600">
        Model: {models}
      </Typography>
      <Typography key={"label-period"} variant="h6" className="font-normal text-blue-gray-600">
        Period: {formatPeriod(data.period)}
      </Typography>
      <Typography key={"matches"} variant="h6" color="blue-gray">
        [Matches: {analysisData.noResults}] [Excluded: {analysisData.noExcluded}]
      </Typography>

      <div className="grid grid-cols-10 grid-rows-1">
        <div className="col-span-9 px-2">
          <Typography>Price:</Typography>
          <StyledSlider
            slots={{ thumb: ArrowThumbComponent }}
            value={[analysisData.minPrice, analysisData.avgPrice, analysisData.maxPrice]}
            valueLabelDisplay="auto"
            min={analysisData.minPrice}
            max={analysisData.maxPrice}
            marks={priceMarks}
          />
        </div>
        <div className="col-span-1 justify-center content-center">
          <Chart {...chartConfigPrice} />
        </div>
      </div>    

      <div className="grid grid-cols-10 grid-rows-1">
        <div className="col-span-9 px-2">
        <Typography>Year:</Typography>
        <StyledSlider
          slots={{ thumb: ArrowThumbComponent }}
          value={[analysisData.minYears, analysisData.avgYears, analysisData.maxYears]}
          valueLabelDisplay="auto"
          min={analysisData.minYears}
          max={analysisData.maxYears}
          marks={yearMarks}
        />
        </div>
        <div className="col-span-1 justify-center content-center">
          <Chart {...chartConfigYears} />
        </div>
      </div>              
      

      <div className="grid grid-cols-10 grid-rows-1">
        <div className="col-span-9 px-2">
        <Typography>Days Active:</Typography>
        <StyledSlider
          slots={{ thumb: ArrowThumbComponent }}
          value={[analysisData.minDaysActive, analysisData.avgDaysActive, analysisData.maxDaysActive]}
          valueLabelDisplay="auto"
          min={analysisData.minDaysActive}
          max={analysisData.maxDaysActive}
          marks={daysActiveMarks}
        />      
      </div>
        <div className="col-span-1 justify-center content-center">
          <Chart {...chartConfigDaysActive} />
        </div>
      </div>       

      

      <div className="grid grid-cols-10 grid-rows-1">
        <div className="col-span-9 px-2">
        <Typography>Total Time:</Typography>
        <StyledSlider
          slots={{ thumb: ArrowThumbComponent }}
          value={[analysisData.minTT, analysisData.avgTT, analysisData.maxTT]}
          valueLabelDisplay="auto"
          min={analysisData.minTT}
          max={analysisData.maxTT}
          marks={totalTimeMarks}
        />            
      </div>
        <div className="col-span-1 justify-center content-center">
          <Chart {...chartConfigTT} />
        </div>
      </div>  

      <div className="grid grid-cols-10 grid-rows-1">
        <div className="col-span-9 px-2">
          <Typography>{analysisData.noEnginesMode > 1 ? "Engine 1 Time" : "Engine Time"}:</Typography>
          <StyledSlider
            slots={{ thumb: ArrowThumbComponent }}
            value={[analysisData.minE1T, analysisData.avgE1T, analysisData.maxE1T]}
            valueLabelDisplay="auto"
            min={analysisData.minE1T}
            max={analysisData.maxE1T}
            marks={engine1TimeMarks}
          />            
        </div>
        <div className="col-span-1 justify-center content-center">
          <Chart {...chartConfigE1T} />
        </div>
      </div>             

      {(analysisData.noEnginesMode === 2) && (

        <div className="grid grid-cols-10 grid-rows-1 h-full">
          <div className="col-span-9 px-2">
            <Typography>Engine 2 Time:</Typography>
            <StyledSlider
              slots={{ thumb: ArrowThumbComponent }}
              value={[analysisData.minE2T, analysisData.avgE2T, analysisData.maxE2T]}
              valueLabelDisplay="auto"
              min={analysisData.minE2T}
              max={analysisData.maxE2T}
              marks={engine2TimeMarks}
            />            
          </div>
          <div className="col-span-1 justify-center content-center">
            <Chart {...chartConfigE2T} />
            
          </div>
        </div>              
      )}
      
 
  </div>)
  }

  
  return (
    <>
    {editDialog()}
    <Dialog
        open={dialogOpen}
        size={"xl"}
        handler={handleOpen}
      >
        <DialogBody className="overflow-y-scroll">
          <ListingsView models={data.models} manufacturer={data.manufacturers[0].value.ids} title={data.name} period={data.period} refreshData={getDashboardAnalysis}></ListingsView>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => handleClose(null)}
            className="mr-1">
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    <Card>
      <CardHeader
        variant="gradient"
        color={"blue"}
        className="mb-4 grid h-10 place-items-center"
      >
        <Typography key={"name"} variant="h6">
          {data.name}
        </Typography>

        <IconButton
          size="sm"
          color="white"
          variant="text"
          onClick={(e) => deleteDashPanel(data.panelId)}
          className="!absolute top-2 right-4 rounded-full w-5 h-5 "
        >
        <TrashIcon
          className="w-5 h-5"
        />
        </IconButton> 

        <IconButton
          size="sm"
          color="white"
          variant="text"
          onClick={(e) => handleEditOpen(e)}
          className="!absolute top-2 left-4 rounded-full w-5 h-5 "
        >
        <PencilSquareIcon
          className="w-5 h-5"
        />
        </IconButton> 

      </CardHeader>
      <CardBody className="p-4 min-h-96">

      {panels()}

      </CardBody>
        <CardFooter className="flex-end">
            <Button className="flex justify-end" onClick={(e)=>handleOpen(data)}>View Listings</Button>
        </CardFooter>
    </Card>
    </>
  );
}

DashboardCard.defaultProps = {
  color: "blue",
  footer: null,
};

DashboardCard.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  footer: PropTypes.node,
};

DashboardCard.displayName = "/src/widgets/cards/dashboard-card.jsx";

export default DashboardCard;
