// import { Form } from "react-router-dom";
import ConrollerPageStatus from "../../components/ConrollerPageStatus";
import React, { useEffect, useState } from "react";
import Caller from "../../components/Caller";
import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { connectFunctionsEmulator } from "firebase/functions";
import { friendlyTimeFormatDt } from "../../utils";
import { collection, getCountFromServer, query, where } from "firebase/firestore";
import db from "../../firebase";

export default function AdminPanel() {

  const [scrapeId, setScrapeId] = useState("");
  const [userAgentData, setUserAgentData] = useState("");
  const [userAgentDataToSend, setUserAgentDataToSend] = useState("");
  const [proxyData, setProxyData] = useState("");
  const [proxyDataToSend, setProxyDataToSend] = useState("");
  const [uniqueListingId, setUniqueListingId] = useState("");
  const [userData, setUserData] = useState([])

  const [listingAggregationsCount, setListingAggregationsCount] = useState();
  const [version2Count, setVersion2Count] = useState();
  const [locV1Count, setLovV1Count] = useState();
  const [planecheckListingAggregationsCount, setPlanecheckListingAggregationsCount] = useState();
  const [dealerNameListingAggregationsCount, setDealerNameListingAggregationsCount] = useState();
  const [photosListingAggregationsCount, setPhotosListingAggregationsCount] = useState();
  const [ccListingAggregationsCount, setCCListingAggregationsCount] = useState();

  const queryListingAggregationsNoLimit = query(
    collection(db, "listing_aggregations_v1"),
  );

  const queryListingAggregationsV1 = query(
    collection(db, "listing_aggregations_v1"),
    where("version", "==", 2.0)
  );

  const queryListingAggregationsLocV1 = query(
    collection(db, "listing_aggregations_v1"),
    where("locV", "==", 1.0)
  );

  const queryListingAggregationsPlanecheck = query(
    collection(db, "listing_aggregations_v1"),
    where("source", "==", "planecheck")
  );

  const queryListingAggregationsDealername = query(
    collection(db, "listing_aggregations_v1"),
    where("dealerName", "!=", "_TEST_")
  );

  const queryListingAggregationsPhotos = query(
    collection(db, "listing_aggregations_v1"),
    where("np", "!=", "_TEST_")
  );

  const queryListingAggregationsCC = query(
    collection(db, "listing_aggregations_v1"),
    where("cc", "!=", "_TEST_")
  );

  useEffect(()=>{
    getCountFromServer(queryListingAggregationsNoLimit)
    .then((snapshot)=>{
      console.log(`got cleaned count:${snapshot.data().count}`)
      setListingAggregationsCount(snapshot.data().count);
    })
  }, [queryListingAggregationsNoLimit])

  useEffect(()=>{
    getCountFromServer(queryListingAggregationsV1)
    .then((snapshot)=>{
      console.log(`got cleaned count:${snapshot.data().count}`)
      setVersion2Count(snapshot.data().count);
    })
  }, [queryListingAggregationsV1])

  useEffect(()=>{
    getCountFromServer(queryListingAggregationsLocV1)
    .then((snapshot)=>{
      console.log(`got cleaned count:${snapshot.data().count}`)
      setLovV1Count(snapshot.data().count);
    })
  }, [queryListingAggregationsLocV1])

  useEffect(()=>{
    getCountFromServer(queryListingAggregationsPlanecheck)
    .then((snapshot)=>{
      console.log(`got cleaned count:${snapshot.data().count}`)
      setPlanecheckListingAggregationsCount(snapshot.data().count);
    })
  }, [queryListingAggregationsPlanecheck])

  useEffect(()=>{
    getCountFromServer(queryListingAggregationsDealername)
    .then((snapshot)=>{
      console.log(`got dealer name count:${snapshot.data().count}`)
      setDealerNameListingAggregationsCount(snapshot.data().count);
    })
  }, [queryListingAggregationsDealername])

  useEffect(()=>{
    getCountFromServer(queryListingAggregationsDealername)
    .then((snapshot)=>{
      console.log(`got dealer name count:${snapshot.data().count}`)
      setDealerNameListingAggregationsCount(snapshot.data().count);
    })
  }, [queryListingAggregationsDealername])

  useEffect(()=>{
    getCountFromServer(queryListingAggregationsPhotos)
    .then((snapshot)=>{
      console.log(`got photoname count:${snapshot.data().count}`)
      setPhotosListingAggregationsCount(snapshot.data().count);
    })
  }, [queryListingAggregationsPhotos])


  useEffect(()=>{
    getCountFromServer(queryListingAggregationsCC)
    .then((snapshot)=>{
      console.log(`got CHAR count:${snapshot.data().count}`)
      setCCListingAggregationsCount(snapshot.data().count);
    })
  }, [queryListingAggregationsCC])


  const functions = getFunctions();

  console.log('me ' + window.location.href);

  if (window.location.href.indexOf('localhost') > -1) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  }
  
  useEffect(() => {

    const func = httpsCallable(functions, "getUserStatus");
    func().then((data)=>{
      setUserData(data.data)
    }).catch((e)=>{
      console.error(e)
    })
  },[functions]);


  const TABLE_HEAD = ["Id", "Email", "Last Active", "Admin"];


  return (
    <div className="mt-12">
      <div className="mt-12 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
            User data
          </CardHeader>
          <CardBody>
          <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {userData.map(({ userId, email, lastActive, isAdmin }, index) => {
            const isLast = index === userData.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
 
            return (
              <tr key={userId}>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {userId}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {email}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {lastActive ? friendlyTimeFormatDt(new Date(lastActive)) : ""}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {isAdmin.toString()}
                  </Typography>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
          </CardBody>
        </Card>
      </div>
      <div>
        <h1>Listing Aggregations Count </h1>
        <span>{listingAggregationsCount}</span>
        <h1>Version 2 count </h1>
        <span>{version2Count}</span>
        <h1>Location Version 1 count </h1>
        <span>{locV1Count}</span>
        <h1>PlaneCheck Listing Aggregations Count </h1>
        <span>{planecheckListingAggregationsCount}</span>
        <h1>Listing Aggregations Count DealerName</h1>
        <span>{dealerNameListingAggregationsCount}</span>
        <h1>Listing Aggregations Count PHOTOS</h1>
        <span>{photosListingAggregationsCount}</span>
        
        <h1>Listing Aggregations Count CC</h1>
        <span>{ccListingAggregationsCount}</span>

      </div>
      <div>
        <ConrollerPageStatus />
      </div>
      <div>
        {/* <Caller
          text="Controller Listing Summary (pageLimit = 1)"
          functionName="reprocessListingAggregation"
          params={{ pageLimit: 1 }}
        ></Caller>
        <Caller
          text="Planecheck Listing Summary (pageLimit = 1)"
          functionName="test"
          params={{ pageLimit: 1 }}
        ></Caller> */}
        <textarea
          placeholder="scrapeId"
          value={scrapeId}
          onChange={(e) => setScrapeId(e.target.value)}
        />
        <textarea
          placeholder="uniqueListingId"
          value={uniqueListingId}
          onChange={(e) => setUniqueListingId(e.target.value)}
        ></textarea>
      </div>
      <div>
        <Caller
          text="was listing in scrape"
          functionName="wasListingInScrape"
          params={scrapeId}
        ></Caller>
      </div>
      <div>
        <Caller
          text="Add user-agents"
          functionName="saveUserAgents"
          params={userAgentDataToSend}
        ></Caller>
         <textarea
          placeholder="JSON"
          value={userAgentData}
          onChange={(e) => {
            setUserAgentData(e.target.value);
            setUserAgentDataToSend(`{"data": ` + userAgentData + `}`)
          }}
        />
      </div>
      <p>
        {userAgentDataToSend}
      </p>
      <div>
        <Caller
          text="Add proxies"
          functionName="addProxies"
          params={proxyDataToSend}
        ></Caller>
         <textarea
          placeholder="JSON"
          value={userAgentData}
          onChange={(e) => {
            setProxyData(e.target.value);
            setProxyDataToSend(`{"data": ` + proxyData + `}`)
          }}
        />
      </div>
      <p>
        {userAgentDataToSend}
      </p>
    </div>
  );
}
