import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  Input,
} from "@material-tailwind/react";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { useState } from "react";

export function ReprocessingView() {

  const functions = getFunctions();
  console.log('me ' + window.location.href);

  if (window.location.href.indexOf('localhost') > -1) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  }

  const [id, setID] = useState("");
  const [response, setResponse] = useState({msg: "", error: false});
  const functionName = "recalcListingAggregation";
  const reprocess = httpsCallable(functions, functionName);

  const handleReprocess = async (uniqueListingId) => {
    setResponse({msg:"",error:false});
    const response = await reprocess({uniqueListingId});
    setResponse(response.data);
  };
  
  const handleChange = (e) =>{
    setID(e.target.value);
  }

  return (
    <div className="mt-12 grid grid-cols-4 gap-4">
      <Card>
        <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
            <Typography className="justify-center" variant="h6" color="white" >
              Re-proceesing
            </Typography>
        </CardHeader>
        <CardBody className="items-center">

          <div className="flex flex-col gap-4">
            <Input onChange={e => handleChange(e)}></Input>
            <Button onClick={()=>handleReprocess(id)}>Re-process</Button>
            {/* <Typography>
              ID: {id}
            </Typography> */}
            <Typography color={response.error ? "red": "green"}>
              {response.msg}
            </Typography>
          </div>

        </CardBody>
      </Card>
    </div>
   
  );
}

export default ReprocessingView;
