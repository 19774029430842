import {
  HomeIcon,
  ChartBarIcon,
  UserCircleIcon,
  BellIcon,
  TableCellsIcon
} from "@heroicons/react/24/solid";
// import ControllerListings from "components/listings-controller";
// import PlanecheckListings from "components/listings-planecheck";
import AdminPanel from "pages/dashboard/AdminPanel";
// import ListingAggregations from "pages/dashboard/ListingAggregations";
// import NewListings from "pages/dashboard/NewListings";
// import Overview from "../pages/dashboard/Overview";
// import { History, Search, Timeline } from "@mui/icons-material";
// import { ListingHistory } from "../components/ListingHistory";
import { Dashboard, Search} from "@mui/icons-material";
import Profile from "pages/dashboard/Profile";
import { AdjustmentsHorizontalIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { SearchListings } from "../components/SearchListings";
import Testing from "pages/dashboard/Testing";
// import Home from "pages/dashboard/home";
// import Tables from "../pages/dashboard/tables";
import ControllerListingsAll from "../components/listings-controller-all";
import PlanecheckListingsAll from "../components/listings-planecheck-all";
import GlobalairListingsAll from "../components/listings-globalair-all";
import ListingAggregationsV1 from "../pages/dashboard/ListingAggregationsV1";
// import ListingsPageRelisted from "../pages/dashboard/ListingsPageRelisted";
import DataCleaningPanel from "../pages/dashboard/DataCleaningPanel";
import AllListings from "../pages/dashboard/AllListings";
import { BrokerView } from "../pages/dashboard/BrokerView";
import DealerView from "../pages/dashboard/DealerView";
import ReprocessingView from "../pages/dashboard/ReprocessingView";
import FXRateView from "../pages/dashboard/FXRateView";
import DashboardPage from "../pages/dashboard/DashboardPage";
import PhotoProcessingPage from "../pages/dashboard/PhotoProcessingPage";


const icon = {
  className: "w-5 h-5 text-inherit",
};

export const routes = [
  {
    layout: "dashboard",
    pages: [
      {
        icon: <TableCellsIcon {...icon} />,
        name: "Controller",
        path: "/controller-listings-all",
        element: <ControllerListingsAll />,
        role: "admin"
      },
      {
        name: "Planecheck",
        icon: <TableCellsIcon {...icon} />,
        path: "/planecheck-listings-all",
        element: <PlanecheckListingsAll />,
        role: "admin"
      },
      {
          icon: <PhotoIcon {...icon} />,
          name: "Photo Processing",
          path: "/photo-processing",
          element: <PhotoProcessingPage />,
          role: "admin"
        },
      // {
      //   icon: <TableCellsIcon {...icon} />,
      //   name: "Ag Grid Test",
      //   path: "/ag-grid-test",
      //   element: <AGGridTest />,
      //   role: "admin"
      // },
      // {
      //   icon: <HomeIcon {...icon} />,
      //   name: "New Listings",
      //   path: "/new-listings",
      //   element: <NewListingsV2 />,
      //   // role: "admin"
      // },
      {
        icon: <Dashboard {...icon} />,
        name: "Dashboard",
        path: "/dashboard",
        element: <DashboardPage />,
        // role: "admin"
      },
      {
        icon: <HomeIcon {...icon} />,
        name: "All Listings",
        path: "/all-listings",
        element: <AllListings />,
      },
      // {
      //   icon: <HomeIcon {...icon} />,
      //   name: "Relisted",
      //   path: "/listings-rl",
      //   element: <ListingsPageRelisted />,
      //   role: "admin"
      // },
      // {
      //   icon: <ChartBarIcon {...icon} />,
      //   name: "price analysis",
      //   path: "/listing-aggregations",
      //   element: <ListingAggregations />,
      //   role: "admin"
      // },
      {
        icon: <ChartBarIcon {...icon} />,
        name: "price analysis",
        path: "/listing-aggregations-v1",
        element: <ListingAggregationsV1 />,
      },
      {
        icon: <ChartBarIcon {...icon} />,
        name: "broker view",
        path: "/broker-view",
        element: <BrokerView />,
        // role: "admin"
      },
      {
        icon: <ChartBarIcon {...icon} />,
        name: "dealer view",
        path: "/dealer-view",
        element: <DealerView />,
        role: "admin"
      },
      {
        icon: <ChartBarIcon {...icon} />,
        name: "re-processing view",
        path: "/reprocessing-view",
        element: <ReprocessingView />,
        role: "admin"
      },
      {
        icon: <ChartBarIcon {...icon} />,
        name: "FX rate view",
        path: "/fx-rate-view",
        element: <FXRateView />,
        role: "admin"
      },

      // {
      //   icon: <Timeline {...icon} />,
      //   name: "Listings Overview",
      //   path: "/listings-overview",
      //   element: <Overview />,
      // },
      // {
      //   icon: <History {...icon} />,
      //   name: "History",
      //   path: "/listings-history",
      //   element: <ListingHistory />,
      // },
      {
        icon: <Search {...icon} />,
        name: "Search",
        path: "/search",
        element: <SearchListings />,
      },
      // {
      //   icon: <HomeIcon {...icon} />,
      //   name: "dashboard",
      //   path: "/home",
      //   element: <Home />,
      // },
      {
        icon: <AdjustmentsHorizontalIcon {...icon} />,
        name: "Admin Panel",
        path: "/admin-panel",
        element: <AdminPanel />,
        role: "admin"
      },
      {
        icon: <AdjustmentsHorizontalIcon {...icon} />,
        name: "Testing",
        path: "/testing",
        element: <Testing />,
        role: "admin"
      },

      {
        icon: <UserCircleIcon {...icon} />,
        name: "profile",
        path: "/profile",
        element: <Profile />,
      },
      {
        name: "Globalair",
        icon: <TableCellsIcon {...icon} />,
        path: "/globalair-listings-all",
        element: <GlobalairListingsAll />,
        role: "admin"
      },
      {
        name: "Data Cleaning",
        icon: <BellIcon {...icon} />,
        path: "/data-cleaning",
        element: <DataCleaningPanel />,
        role: "admin"
      },
      // {
      //   name: "Table",
      //   icon: <BellIcon {...icon} />,
      //   path: "/tables",
      //   element: <Tables />,
      // }
    ],
  },
  // {
  //   title: "detail routes",
  //   layout: "details",
  //   routes: [
  //     {
  //       icon: <ArrowRightOnRectangleIcon {...icon} />,
  //       name: "listing details",
  //       path: "/listings/:slug",
  //       element: <Login />,
  //     }
  //   ],
  // },
   // {
  //   title: "auth pages",
  //   layout: "auth",
  //   pages: [
  //     {
  //       icon: <ArrowRightOnRectangleIcon {...icon} />,
  //       name: "sign in",
  //       path: "/sign-in",
  //       element: <Login />,
  //     },
  //     {
  //       icon: <UserPlusIcon {...icon} />,
  //       name: "sign up",
  //       path: "/sign-up",
  //       element: <Signup />,
  //     },
  //   ],
  // },
  
];

export default routes;
