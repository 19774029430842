import { Button } from "@material-tailwind/react";
import { collectionGroup, getDocs, query } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import db from "../../firebase";


export function PhotoProcessingPage() {

  const functions = getFunctions();

  console.log('me ' + window.location.href);

  if (window.location.href.indexOf('localhost') > -1) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  }

  
  const test = ()=>{
    console.time('model');
    const func = httpsCallable(functions, "getModelData");
    func().then((data)=>{
      console.timeEnd('model');
      console.log(`PP DATA`,data)
    }).catch((e)=>{
      console.error(e)
    })
  }

    
  const testDealersEmulator = ()=>{
    console.time('dealers');
    const func = httpsCallable(functions, "testDealerRetrieval");
    func().then((data)=>{
      console.timeEnd('dealers');
      console.log(`PP DATA`,data.data.len)
    }).catch((e)=>{
      console.error(e)
    })
  }
  
  async function getCollectionGroupData(dataName) {
    console.time('model-local');
    const ref = collectionGroup(db, dataName);
    const q = query(ref);
    const res = await getDocs(q);
    console.timeEnd('model-local');
    return res;
  }

  const testLocal = ()=>{
    console.time('model-local');
    getCollectionGroupData("model_data")
  }


  return (
  <>
      <div>
        <h5>PhotoProcessingPage</h5>
        1) write a function that looks at all listing aggregations and extracts all urls into listing aggregation subcollection 
          - when the urls are being saved, we try to save the image to firebase storage for each url 
          - we record the 'download url' as well if stored correctly 
          - the download url lets us know we have stored the image
          - also save the savedDt so we can see when images where saved

        2) try to include a feature wehre we check when the images have changed
      </div>
      <div className="mt-12">
        <div className="mb-12 grid gap-y-12 gap-12 gap-x-6 md:grid-cols-3 xl:grid-cols-3">
          <Button onClick={()=>{test()}}>Get Model Via Emulators</Button>
        </div>
      </div>
      <div className="mt-12">
        <div className="mb-12 grid gap-y-12 gap-12 gap-x-6 md:grid-cols-3 xl:grid-cols-3">
          <Button onClick={()=>{testLocal()}}>Get Model Local</Button>
        </div>
      </div>
      <div className="mt-12">
        <div className="mb-12 grid gap-y-12 gap-12 gap-x-6 md:grid-cols-3 xl:grid-cols-3">
          <Button onClick={()=>{testDealersEmulator()}}>Get Dealers Via Emulators</Button>
        </div>
      </div>
      
      
    </>
  );
}

export default PhotoProcessingPage;
