import Select from 'react-select'
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import PeriodSelector from './PeriodSelector';

export function DashboardConstraintSelector({
  setSelectedManufacturer, 
  setSelectedModel, 
  selectedManufacturer, 
  selectedModel,
  selectedPeriod, 
  setSelectedPeriod
}) {
  
  const dataContext = useContext(DataContext);
  
  const manufacturerData = dataContext.getManufacturerData();
  const modelData = dataContext.getModelData();
  

  const manufacturerOptions = ()=>{
    const all = [{value: "All Manufacturers", label: "All Manufacturers"}];
    if (!Object.keys(manufacturerData).length){
      return [];
    }
    return all.concat(Object.keys(manufacturerData).sort().map((k)=> ({value: manufacturerData[k], label: k}))).sort();
  }

  const modelOptions = () => {
    const all = [{value: "All Models", label: "All Models"}];
    if (!Object.keys(manufacturerData).length || !Object.keys(modelData).length ){
      return all;
    }
    if (selectedManufacturer.label === "All Manufacturers"){
      return [];
    } else {
      const options = all;

      // for each model, check if the model is relevant for the manufacturer
      for(const [key, value] of Object.entries(modelData)){
        const manufacturersForNthModel = new Set(value.manufacturers);
        const manufacturerIdsForSelectedModel = new Set(selectedManufacturer.value.ids);
        const hasIntersectionManufacturers = manufacturersForNthModel.intersection(manufacturerIdsForSelectedModel).size;

        if (hasIntersectionManufacturers){
          options.push({value: value, label: key});
        }
      }
      return options.sort();
    }
  }

  const setManufacturerClearModel = (e) => {
    setSelectedManufacturer(e);
    setSelectedModel({label: 'All Models', value: 'All Models'});
  }

  const setModel = (e) => {
    setSelectedModel(e);
  }

  return (
  <>
    <div className="grid grid-cols-1 gap-4 items-center">
      <div>
        <Select options={manufacturerOptions()} value={selectedManufacturer} onChange={(e)=>setManufacturerClearModel(e)}></Select>
      </div>
      <div>
        <Select isMulti options={modelOptions()} value={selectedModel} onChange={(e)=> setModel(e)}></Select>
      </div>
      <div className='place-items-center'>

        <PeriodSelector selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod}></PeriodSelector>

      </div>
        
    </div>
  </>);
}

export default DashboardConstraintSelector;
