import { createContext } from "react"
import { formatPrice, formatPriceChange, friendlyTimeFormat } from "../utils";

const LISTING_VIEW_COL_DEFS = 
[
    { field: "manufacturer", filter: "agTextColumnFilter", filterParams: { buttons: ["apply", "reset"]}},
    { 
      headerName:"Reg.", 
      field: "reg", 
      filter: 'agTextColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]}
    },    
    { field: "model", filter: "agTextColumnFilter", filterParams: { buttons: ["apply", "reset"]}},
    { field: "source", filter: "agTextColumnFilter", filterParams: { buttons: ["apply", "reset"]}},
    { field: "year", filter: 'agNumberColumnFilter', filterParams: { buttons: ["apply", "reset"]}},
    { field: "dealer", filter: "agTextColumnFilter", filterParams: { buttons: ["apply", "reset"]}},
    { field: "noPhotos", filter: "agNumberColumnFilter", filterParams: { buttons: ["apply", "reset"]}},
    // { field: "avs", filter: "agNumberColumnFilter", filterParams: { buttons: ["apply", "reset"]}},
    {
      headerName:"TT",  
      field: "total_time", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]}
    },
    { 
      field: "price", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      valueFormatter: params => { return formatPrice(params.value.price, params.value.currency); },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (isNaN(valueA.price_usd) && !isNaN(valueB.price_usd)) return -1;
        if (isNaN(valueA.price_usd) && isNaN(valueB.price_usd)) return 0;
        if (!isNaN(valueA.price_usd) && isNaN(valueB.price_usd)) return 1;
        if (valueA.price_usd === valueB.price_usd) return 0;
        return (valueA.price_usd > valueB.price_usd) ? 1 : -1;
      },
      filterValueGetter: params => {
        return params.data.price.price_usd;
      }
    },
    { 
        headerName:"Last Δ", 
        field: "last_change_dt", 
        filter: 'agNumberColumnFilter', 
        filterParams: { buttons: ["apply", "reset"]},
        valueFormatter: params => { return friendlyTimeFormat(params.value); },
    },
    { 
      headerName:"Δ", 
      field: "price_change_usd_abs", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      valueFormatter: params => { return formatPrice(params.value, "USD"); },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (isNaN(valueA) && !isNaN(valueB)) return -1;
        if (isNaN(valueA) && isNaN(valueB)) return 0;
        if (!isNaN(valueA) && isNaN(valueB)) return 1;
        if (valueA === valueB) return 0;
        return (valueA > valueB) ? 1 : -1;
      }
    },
    { 
      headerName:"Δ %", 
      field: "price_change_pct_abs_usd", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      valueFormatter: params => { return formatPriceChange(params.value); },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (isNaN(valueA) && !isNaN(valueB)) return -1;
        if (isNaN(valueA) && isNaN(valueB)) return 0;
        if (!isNaN(valueA) && isNaN(valueB)) return 1;
        if (valueA === valueB) return 0;
        return (valueA > valueB) ? 1 : -1;
      }
    },
    { 
      headerName:"#Δ", 
      field: "noChanges", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
    },
    { 
      headerName:"First Seen", 
      field: "first_seen", 
      filter: 'agTextColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      valueFormatter: params => { return friendlyTimeFormat(params.value); },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA === valueB) return 0;
        return (valueA > valueB) ? 1 : -1;
      }
    },    
    { 
      headerName:"Days Active", 
      field: "days_active", 
      filter: 'agNumberColumnFilter', 
      filterParams: { buttons: ["apply", "reset"]},
      valueFormatter: params => { return Math.round(params.value); }
    }      
  ];

  function subsetDefs(fields){
    return LISTING_VIEW_COL_DEFS.filter((d)=>!fields.includes(d.field))
  }

  export const ListingViewContext = createContext({
    colDefs: LISTING_VIEW_COL_DEFS,
    subsetDefs

  });